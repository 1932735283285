import React from 'react'
import lepaCorona from '../downloads/LEPA-Corona-Virus.pdf'
import lepaAbacusPdf from '../downloads/17_01_abacus_research_und_lehmannpartner_informatik_spannen_zusammen.pdf'

import lepaAbacus from '../images/news/lepaAbacus.png'
import G4 from '../images/news/G4.png'
import lepaCH from '../images/news/lepaCH.png'
import abacusCH from '../images/news/abacusCH.png'
import CoronavirusNews from '../images/news/CoronavirusNews.jpg'
import DA from '../images/news/LEPA News 20200714 Abacus Data Analyser.jpg'
import linkedin from '../images/news/linkedin.jpg'

export const news = [
    {
        date: '02.12.2021',
        title: 'Alle Newseinträge auf der LinkedIn Plattform',
        text: 'Liebe Leser, schon seit geraumer Zeit werden unsere News auf der Plattform LinkedIn publiziert. Alles rund um Abacus HR, HR Prozesse und Portale finden Sie unter den Beiträgen der LEHMANN+PARTNER Seite auf LinkedIn. Viel Spass beim lesen und schauen ...',
        link: 'https://www.linkedin.com/company/lehmannpartner',
        linkName: 'Hier gehts zu den News von LEPA auf LinkedIn',
        img: linkedin
    },
    {
        date: '14.07.2020',
        title: '50% Rabatt auf Abacus Dataanalyser',
        text: <>Abacus gewährt 50% Rabatt auf die einmaligen Kosten für das Modul Abacus Data Analyser. Diese Aktion gilt bis zum 31.12.2020. Es warten viele HR Dashboards auf Sie auch im HR Portal MyAbacus. Falls Sie interessiert sind wenden Sie sich an <a href="mailto:martin.lehmann@lepa.ch">martin.lehmann@lepa.ch</a></>,
        link: 'https://deepv.swiss/doc/6818a0d9-47d4-40ae-a5df-a621e36755ce',
        linkName: 'Testen Sie es gleich',
        img: DA
    },
    {
        date: '15.04.2020',
        title: 'Abacus mit neuem Webauftritt',
        text: 'Unser Hersteller hat einen neuen Webauftritt. Hier gibt es u.a. viele Informationen zu Abacus HR',
        link: 'https://www.abacus.ch',
        linkName: 'Neue Abacus Webseite',
        img: abacusCH
    },
    {
        date: '18.03.2020',
        title: 'LEHMANN+PARTNER Informatik und der Corona-Virus',
        text: 'Ein Einblick wie LEHMANN+PARTNER Informatik heute arbeitet',
        link: lepaCorona,
        linkName: 'PDF Dokument',
        img: CoronavirusNews
    },
    {
        date: '29.02.2020',
        title: 'LEHMANN+PARTNER Informatik AG aus Luzern mit neuer Webseite',
        text: 'Alles zum Thema Abacus und HR, Lohn und Zeit. Wir wünschen viel Spass beim surfen auf unseren Informationen.',
        link: 'https://www.lepa.ch',
        linkName: 'LEHAMNN+PARTNER Webseite',
        img: lepaCH
    },
    {
        date: '16.03.2018',
        title: 'Abacus G4: Wichtigste Neuerungen der Version 2018 auf einen Blick',
        text: 'Lesen Sie über die neuen Funktionen der Version 2018. HR Portal, HR Prozesse und vieles mehr.',
        link: 'https://classic.abacus.ch/fileadmin/mediendatenbank/sonderdrucke/neuerungen/sd_neuerungen_2018_de.pdf',
        linkName: 'PDF Dokument',
        img: G4
    },
    {
        date: '24.11.2017',
        title: 'Abacus stellt mit "Generation four" seine vierte Softwaregeneration vor',
        text: 'Die aus der ERP-Software, Cloud-Diensten und mobilen Applikationen bestehende Abacus-Plattform erhält eine Erweiterung in Form eines HR-Portals. LEHMANN+PARTNER hat dabei als Entwicklungspartnerin tatkräftig mitgewirkt. Die Zusammenarbeit wird fortgesetzt.',
        link: 'https://media.abacus.ch/abacus/newsroom/medienmitteilungen/mm20171124_de.pdf',
        linkName: 'Link zur Pressemitteilung bei Abacus',
        img: G4
    },
    {
        date: '17.01.2017',
        title: 'Abacus Research und LEHMANN+PARTNER Informatik spannen zusammen',
        text: 'LEHMANN+PARTNER Informatik, langjährige Businesspartnerin der Soreco HR, wird per sofort Vertriebs- und Entwicklungspartnerin für Payroll, HR-Portale und HR-Prozesse des Softwarehauses Abacus Research. Die beiden Unternehmen planen, bis Herbst 2017 gemeinsam ein neues, modernes HR-Portal für Employee-Self-Service (ESS) und Management-Self-Service (MSS) mit einer umfassenden HR-Prozesspalette anzubieten.',
        link: lepaAbacusPdf,
        linkName: 'Link zur Pressemitteilung bei Abacus',
        img: lepaAbacus
    },
]