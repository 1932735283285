import React from 'react'
import Layout from '../components/layout'
import { Row, Col } from 'reactstrap';
import { news } from '../conf/news'

const renderNewsPosts = () => {
    return news.map((post, index) => {
        return (
            <div className="news-post" key={"div_" + index}>
                <Row className="news-post-row">
                    <Col className="img-container" xs="0" sm="0" md="4"><img src={post.img} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                        <h5>{post.date}</h5>
                        <h3>{post.title}</h3>
                        <p>{post.text}</p>
                        {post.link ? <a href={post.link} target="_blank" rel="noopener noreferrer">{post.linkName}</a> : ''}
                    </Col>
                </Row>
            </div>
        )
    })
}

const NewsForm = () => (
    <Layout>
        <header className="section-header">
            <h3>News</h3>
        </header>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
            {renderNewsPosts()}
        </div>
        <br />
    </Layout>
)


export default NewsForm
